export const complaintColumn = [
  {
    field: "policyNumber",
    headerName: "Policy Number",
  },
  {
    field: "policyHolder",
    headerName: "Policy Holder",
  },
  {
    field: "createdDate",
    headerName: "Date Opened",
    type: "date",
  },
  {
    field: "currentStatus",
    headerName: "Current Status",
    type: "status"
  },
  {
    field: "statusdDate",
    headerName: "Status Date",
    type: "date",
  },
  {
    field: "issue",
    headerName: "Issue(s)",
  },
];
