import { NavigationBar } from "./navigationBar/NavigationBar";
import styled from "styled-components";
import ErrorAlert from "../shared/components/ErrorAlert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../store/slices/ErrorSlice";
import { useMsal } from "@azure/msal-react";
import {
  setIsProvider,
  setPolicyNumber,
  setUserName,
} from "../store/slices/UserSlice";

const Container = styled.div`
  margin-top: 90px;
`;
const Wrapper = styled.div`
  padding: 0 40px;
`;

export const PageLayout = (props) => {
  const { instance } = useMsal();
  const error = useSelector((state) => state.error.message);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (activeAccount?.idTokenClaims.extension_SignUpType) {
      dispatch(
        setIsProvider(
          activeAccount?.idTokenClaims.extension_SignUpType === "Provider Code"
        )
      );
      dispatch(
        setPolicyNumber(activeAccount?.idTokenClaims.extension_Policynumber)
      );
      dispatch(setUserName(activeAccount?.idTokenClaims.given_name));
    }
  }, [activeAccount?.idTokenClaims.extension_SignUpType]);

  useEffect(() => {
    if (error) setOpen(true);
  }, [error]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setError(""));
  };
  return (
    <Container>
      <NavigationBar />

      <Wrapper>{props.children}</Wrapper>
      <ErrorAlert handleClose={handleClose} open={open} errorMessage={error} />
    </Container>
  );
};
