export const insuranceCollectionColumn = [
  {
    field: "id",
    headerName: "ID",
    width: 90,
    sortable: false,
  },
  {
    field: "insolvency",
    headerName: "Insolvency",
    width: 150,
  },
  {
    field: "policyNumber",
    headerName: "Policy Number",
    width: 100,
    type: "link",
  },
  {
    field: "form",
    headerName: "Form",
    width: 150,
  },
  {
    field: "eobNumber",
    headerName: "Eob Number",
    sortable: false,
    width: 160,
    type: "eobNumber"
  },
  {
    field: "claimant",
    headerName: "Claimant",
    width: 150,
  },
  {
    field: "provider",
    headerName: "Provider",
    width: 200,
  },
  {
    field: "payToProvider",
    headerName: "Pay To Provider",
    width: 75,
    type: "yesNo",
  },
  {
    field: "serviceStartDate",
    headerName: "Service Dates",
    width: 200,
    type: "period",
  },
  {
    field: "submittedAmount",
    headerName: "Submitted Amount",
    width: 100,
    type: "currency",
  },
  {
    field: "paidAmount",
    headerName: "Paid Amount",
    width: 80,
    type: "currency",
  },
  {
    field: "checkNumber",
    headerName: "Check/ACH Number",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "statusDate",
    headerName: "Status Date",
    width: 150,
    type: "date",
  },
];
