import { Box } from "@mui/material";
import React from "react";
import { TabInfo } from "../components/infoPage/TabInfo";

export const TabPage = () => {
  return (
    <Box>
      <TabInfo />
    </Box>
  );
};
