import { Box, styled } from "@mui/system";

export const ToolsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px 0",
  borderBottom: "6px solid rgba(99, 202, 225, 1)",
});
export const ToolsButtonGroup = styled(Box)({
  display: "grid",
  gridAutoFlow: "column",
  gap: "7px",
  alignItems: "center",
});

export const ButtonWithoutStyle = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.primary.main,
}));
