import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";

export const GroupContainer = styled(Box)({
  backgroundColor: "rgba(204, 208, 231, 0.3)",
  padding: "13px 25px",
  height: "171px",
  maxWidth: "450px",
});
export const StyledTypography = styled(Typography)(({ theme }) => ({
  font: theme.typography.link.font,
  color: theme.palette.primary.header,
  marginBottom: "12px",
}));
export const InputContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "95px 1fr",
  alignItems: "center",
  marginBottom: "12px",
});
