import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const insuranceClaimAPI = createApi({
  reducerPath: "insuranceClaimAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  tagTypes: ["Insurance"],
  endpoints: (build) => ({
    getInsuranceCollection: build.mutation({
      query: (filters) => ({
        url: `api/Insurance/GetInsuranceCollection`,
        method: 'POST',
          body: {
            "insuranceFilterItems": filters
            }
      }),
      transformErrorResponse: (response) => {
        return {
          message:
            response.data?.Message || response.data?.title || response.status,
        };
      },
    }),
    getInsurance: build.query({
      query: (data) => ({
        url: `api/Insurance/GetInsuranceInfo?policyNumber=${
          data.policyNumber
        }&dependentId=${data.id || 0}`,
      }),
      transformResponse: (response) => {
        response.formNumber = response.form;
        return response;
      },

      transformErrorResponse: (response) => {
        return {
          message:
            response.data?.Message || response.data?.title || response.status,
        };
      },
    }),
    getTabs: build.query({
      query: () => ({
        url: `api/Insurance/GetTabCollection`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data?.Message || response.data?.title };
      },
    }),
    getClaims: build.query({
      query: (policyNumber) => ({
        url: `api/Insurance/GetClaims?policyNumber=${policyNumber}`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data?.Message || response.data?.title };
      },
    }),
    getClaimants: build.query({
      query: (policyNumber) => ({
        url: `api/Insurance/GetClaimants?policyNumber=${policyNumber}`,
      }),
      transformResponse: (response) =>
        response.map((item) => {
          return { id: item.id, name: item.fullName };
        }),
      transformErrorResponse: (response) => {
        return { message: response.data?.Message || response.data?.title };
      },
    }),
    getPolicyTracking: build.query({
      query: (id) => ({
        url: `api/Insurance/GetPolicyTracking?policyNumber=${id}`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data?.Message || response.data?.title };
      },
    }),
    getComplaintAppeals: build.query({
      query: () => ({
        url: `api/Insurance/GetComplaintAppeals`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data?.Message || response.data?.title };
      },
    }),
    getProviderName: build.query({
      query: () => ({
        url: `api/Insurance/GetProviderName`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data?.Message || response.data?.title };
      },
    }),
  }),
});
export const {
  useGetInsuranceCollectionMutation,
  useGetInsuranceQuery,
  useGetTabsQuery,
  useGetClaimsQuery,
  useGetClaimantsQuery,
  useGetPolicyTrackingQuery,
  useGetComplaintAppealsQuery,
  useGetProviderNameQuery,
} = insuranceClaimAPI;
