import * as React from "react";
import moment from "moment";
import { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../store/slices/ErrorSlice";
import { LoadingPanel } from "../../shared/components/LoadingPanel";
import StyledGrid from "../../shared/components/StyledGrid";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import { insuranceCollectionColumn } from "../../constants/InsuranceCollectionColumn";
import InputNumberInterval from "./InputNumberInterval";
import { useGetInsuranceCollectionMutation } from "../../services/InsuranceService";

const columns = insuranceCollectionColumn.map((item) => {
  switch (item.type) {
    case "yesNo":
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => (params.row[item.field] ? "Yes" : "No"),
      };
    case "period":
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
        valueGetter: (params) =>
          `${moment(params.row.serviceStartDate)
            .utc()
            .format("MM/DD/YYYY")} - ${moment(params.row.serviceEndtDate)
            .utc()
            .format("MM/DD/YYYY")}`,
      };
    case "date":
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) =>
          moment(params.row[item.field]).utc().format("MM/DD/YYYY"),
      };
    case "currency":
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => `$${params.row[item.field].toFixed(2)}`,
      };
    case "eobNumber":
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
      };
    case "link":
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
        renderCell: (params) => (
          <Link
            className="link"
            to={`/insurance?policyNumber=${params.row.policyNumber}&dependentId=${params.row.dependentId}`}
          >
            {params.row.policyNumber}
          </Link>
        ),
      };
    default:
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      };
  }
});

const quantityOnlyOperators = [
  {
    label: "Between",
    value: "between",
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      return ({ value }) => {
        return (
          value !== null &&
          filterItem.value[0] <= value &&
          value <= filterItem.value[1]
        );
      };
    },
    InputComponent: InputNumberInterval,
  },
];

const InsuranceCollection = () => {
  const { skip } = useFetchWithMsal();
  const [filterModel, setFilterModel] = useState();
  const navigate = useNavigate();
  const [
    getInsuranceCollection,
    { data, isLoading, isSuccess, isError, error },
  ] = useGetInsuranceCollectionMutation("", { skip });
  const [rows, setRows] = useState([]);
  const [filtredColumns, setFiltredColumns] = useState([]);

  const dispatch = useDispatch();

  const { isProvider } = useSelector((state) => state.user);
  useEffect(() => {
    if (isSuccess && !isProvider && !data.length) {
      navigate(`/insurance`);
    }
  }, [isSuccess]);

  const filterProviderColumns = (columns) => {
    if (Array.isArray(columns)) {
      return columns.filter((column) => {
        const excludedFields = [
          "id",
          "insolvency",
          "payToProvider",
          "provider",
        ];
        return !excludedFields.includes(column.field);
      });
    }
    return [];
  };

  function transformFilterObject(input) {
    const result = {
      insuranceFilterItems: [],
    };

    if (input?.items && input?.items?.length > 0) {
      input.items.forEach((item) => {
        let searchType;

        switch (item.operator) {
          case "between":
            searchType = 0;
            break;
          case "contains":
            searchType = 1;
            break;
          case "equals":
            searchType = 2;
            break;
          case "startsWith":
            searchType = 3;
            break;
          case "endsWith":
            searchType = 4;
            break;
        }

        const insuranceFilterItem = {
          column: getColumnForField(item.field),
          searchType,
          searchValue: item.value ? item.value : "",
        };

        result.insuranceFilterItems.push(insuranceFilterItem);
      });
    }

    return result;
  }

  function getColumnForField(field) {
    switch (field) {
      case "policyNumber":
        return 1; // PolicyNumber column
      case "eobNumber":
        return 2; // ClaimNumber column
      case "serviceStartDate":
        return 3; // ServiceDates column
      default:
        return 0;
    }
  }

  function isValidDateFormat(inputDate) {
    // Паттерн для проверки формата "MM/DD/YYYY"
    const dateFormatPattern =
      /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

    return dateFormatPattern.test(inputDate);
  }
  useEffect(() => {
    const transformedFiltersObject = transformFilterObject(filterModel);
    const column = transformedFiltersObject.insuranceFilterItems[0]?.column;
    const searchValue =
      transformedFiltersObject.insuranceFilterItems[0]?.searchValue;
    if (!skip) {
      if (column === 3) {
        if (searchValue) {
          getInsuranceCollection([
            {
              column: 3,
              searchType: 0,
              searchValue: `${searchValue[0] ? searchValue[0] : ""};${
                searchValue[1] ? searchValue[1] : ""
              }`,
            },
          ]);
        }
      } else {
        getInsuranceCollection(transformedFiltersObject.insuranceFilterItems);
      }
    }
  }, [filterModel, skip]);

  useEffect(() => {
    isProvider
      ? setFiltredColumns(filterProviderColumns(columns))
      : setFiltredColumns(columns);
  }, [columns]);

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      dispatch(setError(error?.message));
    }
  }, [error, isError]);

  const customFilterColumns = useMemo(() => {
    const newColumns = [...filtredColumns];

    if (newColumns.length > 0) {
      const index = newColumns.findIndex(
        (col) => col.field === "serviceStartDate"
      );
      const quantityColumn = newColumns[index];

      newColumns[index] = {
        ...quantityColumn,
        filterOperators: quantityOnlyOperators,
      };
    }

    return newColumns;
  }, [filtredColumns]);

  return (
    <Box sx={{ height: "100%", width: "100%", margin: "50px auto" }}>
      {isLoading && <LoadingPanel />}
      <StyledGrid
        data={rows}
        width="100%"
        columns={customFilterColumns}
        withFilter
        withMenu
        onFilterModelChange={(model) => setFilterModel(model)}
      />
    </Box>
  );
};

export default InsuranceCollection;
