import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { PageLayout } from "./components/PageLayout";
import { b2cPolicies, protectedResources } from "./authConfig";
import { compareIssuingPolicy } from "./utils/claimUtils";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TabPage } from "./pages/TabPage";
import { ProtectedRoute } from "./components/protectedRoute/ProtectedRoute";
import { HomePage } from "./pages/homePage/HomePage";

const theme = createTheme({
  typography: {
    h1: {
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "24px",
    },
    h2: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "19px",
      marginBottom: "36px",
    },
    h6: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "16.8px",
    },
    subtitle1: {
      fontSize: "10px",
      fontWeight: "400",
      lineHeight: "12px",
    },
    body1: {
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "14px",
    },
    body2: {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "21px",
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: "700",
      lineHeight: "14px",
    },
    button: {
      font: '400 20px "Oswald"',
    },
    link: {
      font: '600 16px "Lato"',
    },
  },
  palette: {
    primary: {
      main: "rgb(92, 6, 140);",
      light: "rgba(0, 0, 0, 0.1)",
      dark: "rgb(92, 6, 140, 0.5)",
      header: "rgba(0, 22, 137, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    info: {
      main: "rgba(255, 107, 87, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    link: {
      main: "rgba(92, 6, 140, 1)",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Lato",
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "14px",
          textAlign: "left",
          marginBottom: "9px",
          color: "rgba(0, 0, 0, 0.6)",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Lato",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0px 11px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "30px",
          padding: "5px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          textTransform: "uppercase",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.1)",
          "&.Mui-active": {
            color: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    },
  },
});

const Pages = () => {
  const { instance } = useMsal();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        if (
          compareIssuingPolicy(
            event.payload.idTokenClaims,
            b2cPolicies.names.forgotPassword
          )
        ) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [
              ...protectedResources.apiTodoList.scopes.read,
              ...protectedResources.apiTodoList.scopes.write,
            ],
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error && event.error.errorMessage.includes("AADB2C90118")) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/insurance" element={<ProtectedRoute />}>
        <Route path="/insurance" element={<TabPage />} />
      </Route>
    </Routes>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <ThemeProvider theme={theme}>
        <PageLayout>
          <Pages />
        </PageLayout>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
