import React from "react";
import { Grid } from "@mui/material";
import InputField from "../../../formFields/InputField";
import SelectField from "../../../formFields/SelectFieldForForm";
import CheckboxField from "../../../formFields/CheckboxField";
import {
  GroupContainer,
  InputContainer,
  StyledTypography,
} from "./PolicyInfo.styled";
import DateField from "../../../formFields/DateField";
import { tabModel } from "../../../../constants/TabFormModel";

export const PolicyInfo = () => {
  const getInputComponent = (field, width, height) => {
    switch (field.type) {
      case "select":
        return (
          <SelectField
            name={field.name}
            label={field.label}
            // data={data}
            fullWidth
            isDisabled
          />
        );
      case "dateInput":
        return (
          <DateField
            name={field.name}
            label={field.label}
            fullWidth
            width={width}
            height={height}
            isDisabled
          />
        );
      case "checkbox":
        return (
          <CheckboxField name={field.name} label={field.label} isDisabled />
        );
      default:
        return (
          <InputField
            name={field.name}
            label={field.label}
            fullWidth
            height={height}
            isDisabled
          />
        );
    }
  };
  return (
    <React.Fragment>
      <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
        <Grid item xs={6}>
          <GroupContainer>
            <StyledTypography variant="h6">Main</StyledTypography>
            <InputContainer>
              {getInputComponent(tabModel.policyNumber)}
            </InputContainer>
            <InputContainer>
              {getInputComponent(tabModel.formNumber)}
            </InputContainer>
            <InputContainer>
              {getInputComponent(tabModel.coverageType)}
            </InputContainer>
            <InputContainer>
              {getInputComponent(tabModel.policyStatus)}
            </InputContainer>
          </GroupContainer>
        </Grid>

        <Grid item xs={6}>
          <GroupContainer>
            <StyledTypography variant="h6">Premium</StyledTypography>

            <InputContainer>
              {getInputComponent(tabModel.premium)}
            </InputContainer>
            <InputContainer>
              {getInputComponent(tabModel.paidToDate)}
            </InputContainer>
            <InputContainer>
              {getInputComponent(tabModel.billingStatus)}
            </InputContainer>
            <InputContainer>
              {getInputComponent(tabModel.billingCycle)}
            </InputContainer>
          </GroupContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
