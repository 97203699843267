import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import { useGridRootProps } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { styled } from "@mui/material";

const StyledDatePicker = styled(DatePicker)((props) => ({
  "& .MuiInputLabel-root": {
    position: "absolute",
    transform: "translate(0, -16px)",
    color: '#000 !important'
  },
  "& .MuiOutlinedInput-root": {
    marginRight: "5px",
    borderBottom: "1px solid rgb(158 158 158)",
    borderRadius: "0px !important",
    marginBottom: "2px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
 
  
}));


const InputNumberInterval = (props) => {
    const rootProps = useGridRootProps();
    const { item, applyValue, focusElementRef = null } = props;
  
    const filterTimeout = useRef();
    const [filterValueState, setFilterValueState] = useState(item.value ?? '');
    const [applying, setIsApplying] = useState(false);
  
    useEffect(() => {
      return () => {
        clearTimeout(filterTimeout.current);
      };
    }, []);
  
    useEffect(() => {
      const itemValue = item.value ?? [undefined, undefined];
      setFilterValueState(itemValue);
    }, [item.value]);
  
    const updateFilterValue = (lowerBound, upperBound) => {
      clearTimeout(filterTimeout.current);
      setFilterValueState([lowerBound, upperBound]);
  
      setIsApplying(true);
      filterTimeout.current = setTimeout(() => {
        setIsApplying(false);
        applyValue({ ...item, value: [lowerBound, upperBound] });
      }, rootProps.filterDebounceMs);
    };

    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString('en-US', options);
    }
  
    const handleUpperFilterChange = (newValue) => {
      const newUpperBound = formatDate(newValue);
      updateFilterValue(filterValueState[0], newUpperBound);
    };
    const handleLowerFilterChange = (newValue) => {
      const newLowerBound = formatDate(newValue);
      updateFilterValue(newLowerBound, filterValueState[1]);
    };
  
    return (
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'end',
          height: 48,
          pl: '20px',
          width: 300
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledDatePicker
            label="From"
            onChange={(newValue) => handleLowerFilterChange(newValue)}
            value={filterValueState[0] ? dayjs(filterValueState[0]) : ''}
            InputLabelProps={{ shrink: true }}
          />
          <StyledDatePicker
            label="To"
            onChange={(newValue) => handleUpperFilterChange(newValue)}
            value={dayjs(filterValueState[1] ? dayjs(filterValueState[1]) : '')}
          />
        </LocalizationProvider>
      </Box>
    );
  }

export default InputNumberInterval;