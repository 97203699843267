import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import { InputLabel, TextField } from "@mui/material";

const InputField = (props) => {
  const { errorText, label, height, width, isDisabled, ...rest } = props;
  const [field, meta] = useField(props);

  const renderHelperText = () => {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    }
  };

  return (
    <>
      <InputLabel htmlFor="input" sx={{ height: "14px", marginBottom: "0" }}>
        {label}
      </InputLabel>
      <TextField
        type="text"
        size="small"
        variant="standard"
        error={meta.touched && meta.error && true}
        helperText={renderHelperText()}
        disabled={isDisabled}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            padding: "0",
          },
        }}
        InputProps={{
          style: {
            height: "14px",
            maxWidth: width || "200px",
            padding: "0",
            fontFamily:'Lato'
          },
          disableUnderline: true,
        }}
        {...field}
        {...rest}
      />
    </>
  );
};

export default InputField;
