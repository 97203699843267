import React, { useEffect, useState } from "react";
import { at } from "lodash";
import { useField } from "formik";
import { InputLabel, TextField } from "@mui/material";
import moment from "moment";

const PeriodField = (props) => {
  const {
    errorText,
    label,
    height,
    width,
    isDisabled,
    startDate,
    endDate,
    ...rest
  } = props;
  const [startField] = useField({ name: startDate });
  const [endField, meta, helper] = useField({ name: endDate });

  const { setValue } = helper;
  const { value: startValue } = startField;

  const { value: endValue } = endField;
  const [date, setDate] = useState();
  useEffect(() => {
    if (startValue && endValue) {
      setDate(
        `${moment(startValue).format("MM/DD/YYYY")}-${moment(endValue).format(
          "MM/DD/YYYY"
        )}`
      );
    }
  }, [startValue, endValue]);
  const renderHelperText = () => {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    }
  };
  const onChange = (event) => {
    setValue(new Date(event.target.value));
    setDate(event.target.value);
  };
  return (
    <>
      <InputLabel htmlFor="input" sx={{ height: "14px", marginBottom: "0" }}>
        {label}
      </InputLabel>
      <TextField
        type="text"
        size="small"
        variant="standard"
        error={meta.touched && meta.error && true}
        value={date}
        onChange={onChange}
        helperText={renderHelperText()}
        disabled={isDisabled}
        color="secondary"
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
        }}
        InputProps={{
          style: { height: "14px", maxWidth: width || "200px", padding: "0" },
          disableUnderline: true,
        }}
        {...rest}
      />
    </>
  );
};

export default PeriodField;
