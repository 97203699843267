import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: "",
  data: {},
};

const complaintEditSlice = createSlice({
  name: "complaintEditSlice",
  initialState,
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setComplaintData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setIsOpen, setComplaintData } = complaintEditSlice.actions;
export default complaintEditSlice.reducer;
