import React, { useEffect, useState } from "react";
import { at } from "lodash";
import { useField } from "formik";
import { InputLabel, TextField } from "@mui/material";
import moment from "moment";

const DateField = (props) => {
  const { errorText, label, height, width, isDisabled, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const { value } = field;
  const [date, setDate] = useState();

  useEffect(() => {
    if (value) {
      setDate(moment(value).utc().format("MM/DD/YYYY"));
    }
  }, [value]);
  const renderHelperText = () => {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    }
  };
  const onChange = (event) => {
    setValue(new Date(event.target.value));
  };
  return (
    <>
      <InputLabel htmlFor="input" sx={{ height: "14px" }}>
        {label}
      </InputLabel>
      <TextField
        type="text"
        size="small"
        error={meta.touched && meta.error && true}
        value={date}
        onChange={onChange}
        helperText={renderHelperText()}
        disabled={isDisabled}
        InputProps={{
          style: {
            height: height || "30px",
            maxWidth: width || "200px",
            width: "100%",
          },
        }}
        {...rest}
      />
    </>
  );
};

export default DateField;
