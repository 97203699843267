import React, { useEffect, useState } from "react";
import { at } from "lodash";
import { useField } from "formik";
import { InputLabel, TextField } from "@mui/material";

const PercentageField = (props) => {
  const { errorText, label, height, width, isDisabled, ...rest } = props;
  const [field, meta] = useField(props);
  const { value } = field;
  const [data, setData] = useState();

  useEffect(() => {
    if (value) {
      const percent = value * 100;
      setData(`${percent.toFixed(2)}%`);
    }
  }, [value]);
  const renderHelperText = () => {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    }
  };

  return (
    <>
      <InputLabel htmlFor="input" sx={{ height: "14px", marginBottom: "0" }}>
        {label}
      </InputLabel>
      <TextField
        type="text"
        size="small"
        variant="standard"
        error={meta.touched && meta.error && true}
        value={data}
        helperText={renderHelperText()}
        disabled={isDisabled}
        color="secondary"
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
        }}
        InputProps={{
          style: { height: "14px", maxWidth: width || "200px", padding: "0" },
          disableUnderline: true,
        }}
        {...rest}
      />
    </>
  );
};

export default PercentageField;
