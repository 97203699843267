import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isProvider: "",
  policyNumber: 0,
  name: "",
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setIsProvider: (state, action) => {
      state.isProvider = action.payload;
    },
    setPolicyNumber: (state, action) => {
      state.policyNumber = action.payload;
    },
    setUserName: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { setIsProvider, setPolicyNumber, setUserName } =
  userSlice.actions;
export default userSlice.reducer;
