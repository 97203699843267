import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const SelectField = (props) => {
  const {
    errorText,
    label,
    data,
    isError,
    width,
    value,
    onChange,
    labelType,
    isDisabled,
    ...rest
  } = props;
  return (
    <div>
      <InputLabel id={label}>{label}</InputLabel>
      <FormControl {...rest} error={isError}>
        <Select
          labelId={label}
          defaultValue={""}
          value={value || (value === 0 ? 0 : "")}
          sx={{ width: width || "270px" }}
          onChange={onChange}
          disabled={isDisabled}
        >
          {data &&
            data?.map((item) => (
              <MenuItem
                key={item.id || item}
                value={item.id || (item.id === 0 ? 0 : item)}
              >
                {item.name || item}
              </MenuItem>
            ))}
        </Select>
        {isError && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </div>
  );
};
export default SelectField;
