import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_SUSI,
    forgotPassword: process.env.REACT_APP_FOGOT_PASSWORD,
    // editProfile: "<Edit Profile Name - in the form of B2C_1_xxx>"
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${process.env.REACT_APP_AUTH_DOMAIN}.b2clogin.com/${process.env.REACT_APP_AUTH_DOMAIN}.onmicrosoft.com/${process.env.REACT_APP_SUSI}`,
    },
    forgotPassword: {
      authority: `https://${process.env.REACT_APP_AUTH_DOMAIN}.b2clogin.com/${process.env.REACT_APP_AUTH_DOMAIN}.onmicrosoft.com/${process.env.REACT_APP_FOGOT_PASSWORD}`,
    },
    // editProfile: {
    //     authority: "https://EMRPoC.b2clogin.com/EMRPoC.onmicrosoft.com/<Edit Profile Name - in the form of B2C_1_xxx>"
    // }
  },
  authorityDomain: `${process.env.REACT_APP_AUTH_DOMAIN}.b2clogin.com`,
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const protectedResources = {
  api: {
    scopes: {
      read: [
        `https://${process.env.REACT_APP_AUTH_DOMAIN}.onmicrosoft.com/${process.env.REACT_APP_API_CLIENT_ID}/Insurance.Read`,
      ],
    },
  },
};

export const loginRequest = {
  scopes: [...protectedResources.api.scopes.read],
};
