import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import SelectField from "../../shared/components/SelectField";

const SelectFieldForForm = (props) => {
  const { label, data, width, isDisabled } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const { value } = field;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;

  const onChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <SelectField
        isError={isError}
        errorText={error}
        label={label}
        data={data}
        width={width || 200}
        value={value}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </>
  );
};
export default SelectFieldForForm;
