import React from "react";
import { Alert, Snackbar } from "@mui/material";

const ErrorAlert = (props) => {
  const { open, handleClose, errorMessage } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};
export default ErrorAlert;
