import styled from "styled-components";

export const NavBar = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

export const NavigationBarContainer = styled.div`
  width: 100%;
  padding: 4px 40px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(92, 6, 140, 1);
  color: #fff;
`;
export const NavigationBarButton = styled.button`
  border: none;
  cursor: pointer;
  background-color: inherit;
  font: 400 20px "Oswald";
  color: #fff;
  display: flex;
  align-items: center;
  height: 30px;
`;
export const NavigationBarUser = styled.div`
  display: flex;
  margin-right: 40px;
  align-items: center;
`;
export const LogoContainer = styled.div`
  background-color: #ccd0e8;
  padding: 6px 40px;
  & img {
    height: 26px;
  }
`;
