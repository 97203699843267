import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import {
  BookInformation20Regular,
  EyeTracking20Regular,
  DocumentBriefcase20Regular,
  EmojiSad20Regular,
} from "@fluentui/react-icons";

import { PolicyTracking } from "./accordionItem/policyTracking/PolicyTracking";
import { LoadingPanel } from "../../shared/components/LoadingPanel";
import {
  useGetInsuranceQuery,
  useGetTabsQuery,
} from "../../services/InsuranceService";
import { Form, Formik } from "formik";
import { PolicyInfo } from "./accordionItem/policyInfo/PolicyInfo";
import { useSearchParams } from "react-router-dom";
import { TabInfoTools } from "./tabInfoTools/TabInfoTools";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../store/slices/ErrorSlice";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import { ClaimsTab } from "./accordionItem/сlaims/ClaimsTab";
import { ComplaintTab } from "./accordionItem/complaint/ComplaintTab";

const HeaderWithTabs = styled.div`
  background-color: white;
  position: sticky;
  top: 80px;
  z-index: 100;
`;

export const TabInfo = () => {
  const [searchParams] = useSearchParams();
  const { policyNumber, isProvider } = useSelector((state) => state.user);
  const { skip } = useFetchWithMsal();

  const { data: tabsData, error: tabError } = useGetTabsQuery("", { skip });
  const { data, isLoading, error } = useGetInsuranceQuery(
    {
      policyNumber: searchParams.get("policyNumber") || policyNumber,
      id: searchParams.get("dependentId"),
    },
    { skip }
  );

  const dispatch = useDispatch();
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [initialValue, setInitialValue] = useState({});
  const [expanded, setExpanded] = useState([1]);

  useEffect(() => {
    dispatch(setError(error?.message || tabError?.message));
  }, [error, tabError]);
 
  useEffect(() => {
    setInitialValue(data);
  }, [data]);
  useEffect(() => {
    if (tabsData) {
      if (!isProvider) {
        setTabs(tabsData);
      } else {
        const filteredTabs = tabsData.filter((item) => item.name !== "Claims");
        setTabs(filteredTabs);
      }
    }
  }, [tabsData]);

  const onTabChange = (isValid) => (event, newValue) => {
    setExpanded([newValue]);
    setActiveTab(newValue);
  };

  const onAccordionClick = (panel) => () => {
    if (expanded.includes(panel)) {
      setExpanded(expanded.filter((item) => item !== panel));
    } else {
      setExpanded([panel]);
      setActiveTab(panel);
    }
  };
  const onCollapseClick = () => {
    setExpanded([]);
  };
  const renderTabIcon = (tab) => {
    switch (tab) {
      case 1:
        return <BookInformation20Regular />;
      case 2:
        return <EyeTracking20Regular />;
      case 3:
        return <DocumentBriefcase20Regular />;
      case 4:
        return <EmojiSad20Regular />;
      default:
        return;
    }
  };
  const renderTabContent = (setValues, tab) => {
    switch (tab) {
      case 1:
        return <PolicyInfo />;
      case 2:
        return (
          <PolicyTracking
            policyNumber={searchParams.get("policyNumber") || policyNumber}
            setValues={setValues}
          />
        );
      case 3:
        return (
          <ClaimsTab
            policyNumber={searchParams.get("policyNumber") || policyNumber}
          />
        );
      case 4:
        return (
          <ComplaintTab
            policyNumber={searchParams.get("policyNumber") || policyNumber}
          />
        );
      default:
        return <Typography>Not Found</Typography>;
    }
  };

  return (
    <Box
      sx={{
        minWidth: "750px",
        maxWidth: "1900px",
        position: "relative",
        width: "100%",
        margin: "0 auto",
      }}
    >
      {isLoading && <LoadingPanel />}
      {true && (
        <Formik enableReinitialize initialValues={initialValue}>
          {({ isValid, dirty, setValues }) => (
            <Form id={"insuranceInfo"}>
              <HeaderWithTabs>
                <TabInfoTools
                  onCollapseClick={onCollapseClick}
                  onExpandAll={() => setExpanded(tabs.map((tab) => tab.id))}
                  userName={
                    data ? `${data.policyHolderName} #${data.policyNumber}` : ""
                  }
                  isDirty={dirty}
                />

                <Box
                  sx={{ borderBottom: 1, borderColor: "divider", mb: "22px" }}
                >
                  <Tabs
                    value={activeTab}
                    onChange={onTabChange(isValid)}
                    aria-label="tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {tabs?.map((tab) => {
                      return (
                        <Tab
                          key={tab.id}
                          label={tab.name}
                          value={tab.id}
                          icon={renderTabIcon(tab.id)}
                          iconPosition="start"
                          sx={{ minHeight: "48px" }}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
              </HeaderWithTabs>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box width={"100%"}>
                  {tabs &&
                    tabs?.map((tab) => {
                      return (
                        <Accordion
                          expanded={expanded.includes(tab.id)}
                          id={`accordion-${tab.id}`}
                          onChange={onAccordionClick(tab.id)}
                          key={tab.id}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${tab.id}-content`}
                            id={`${tab.id}-header`}
                            sx={{ flexFlow: "row-reverse" }}
                          >
                            <Typography variant="link">{tab.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {renderTabContent(setValues, tab.id)}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
