export const tabModel = {
  policyNumber: {
    name: "policyNumber",
    label: "Policy Number",
    validationType: null,
  },
  formNumber: {
    name: "formNumber",
    label: "Form Number",
    validationType: null,
  },
  form: {
    name: "form",
    label: "Form",
    validationType: null,
  },
  coverageType: {
    name: "coverageType",
    label: "Coverage Type",
    validationType: null,
  },
  policyStatus: {
    name: "policyStatus",
    label: "Policy Status",
    validationType: null,
  },
  premium: {
    name: "premium",
    label: "Premium",
    validationType: null,
  },
  paidToDate: {
    name: "paidToDate",
    label: "Paid To Date",
    validationType: null,
  },
  billingStatus: {
    name: "billingStatus",
    label: "Billing Status",
    validationType: null,
  },
  billingCycle: {
    name: "billingCycle",
    label: "Billing cycle",
    validationType: null,
  },
  claimant: {
    name: "claimant",
    label: "Claimant",
    validationType: null,
    type: "select",
  },
  policyOwner: {
    name: "policyOwner",
    label: "Policy Owner",
    validationType: null,
    type: "select",
  },

  planStartDate: {
    name: "planStartDate",
    label: "Plan Period",
    type: "periodStart",
  },
  planEndDate: {
    name: "planEndDate",
    label: "Plan Period",
    type: "periodEnd",
  },
  coinsurance: {
    name: "coinsurance",
    label: "Coinsurance",
    validationType: null,
    type: "percent",
  },
  member: {
    name: "member",
    label: "Member",
    validationType: null,
  },
  INDDED: {
    name: "INDDED",
    label: "IND DED",
    validationType: null,
  },
  INDOOP: {
    name: "INDOOP",
    label: "IND OOP",
    validationType: "phone",
  },
};
