import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { insuranceClaimAPI } from "../services/InsuranceService";
import error from "./slices/ErrorSlice";
import user from "./slices/UserSlice";
import complaintEdit from "./slices/ComplaintEditSlice";

export const store = configureStore({
  reducer: {
    [insuranceClaimAPI.reducerPath]: insuranceClaimAPI.reducer,
    error,
    user,
    complaintEdit,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(insuranceClaimAPI.middleware),
});

setupListeners(store.dispatch);
