import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import InputField from "../../../formFields/InputField";
import PeriodField from "../../../formFields/PeriodField";
import { tabModel } from "../../../../constants/TabFormModel";
import { useGetPolicyTrackingQuery } from "../../../../services/InsuranceService";
import PercentageField from "../../../formFields/PercentageField";
import { InfoBlock, InputContainer } from "./PolicyTracking.styled";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: "Lato",
  fontSize: "12px",
  [`&.${tableCellClasses.head}`]: {
    color: "#787878",
    fontWeight: "700",
    backgroundColor: "#63cae1",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#000",
    fontWeight: "400",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: " 1px solid #e0e0e0",
}));

export const PolicyTracking = ({ policyNumber, setValues }) => {
  const { data: policyTracking } = useGetPolicyTrackingQuery(policyNumber);

  useEffect(() => {
    if (policyTracking) {
      setValues(policyTracking);
    }
  }, [policyTracking]);

  const getInput = (field) => {
    switch (field.type) {
      case "periodStart":
        return (
          <PeriodField
            endDate={"planEndtDate"}
            startDate={field.name}
            label={field.label}
            fullWidth
            isDisabled
          />
        );
      case "percent":
        return (
          <PercentageField
            name={field.name}
            label={field.label}
            fullWidth
            isDisabled
          />
        );

      default:
        return (
          <InputField
            name={field.name}
            label={field.label}
            fullWidth
            isDisabled
          />
        );
    }
  };

  return (
    <>
      <InfoBlock>
        <Box>
          <InputContainer>{getInput(tabModel.policyOwner)}</InputContainer>
          <InputContainer>{getInput(tabModel.coverageType)}</InputContainer>
          <InputContainer>{getInput(tabModel.form)}</InputContainer>
          <InputContainer>{getInput(tabModel.planStartDate)}</InputContainer>
          <InputContainer>{getInput(tabModel.coinsurance)}</InputContainer>
        </Box>
        <Box width={'500px'}>
          <Typography variant="body1">
        The Guaranty Association is responsible for processing all claims
            for services rendered up to the end of August 31st, in accordance
            with the policy's stipulations, subject to a maximum limit of
            $500,000 as per statutory regulations. The ledger below tracks the
            cumulative total of claims applied toward this $500,000 cap,
            specifically indicating the amounts disbursed under <strong>"GA Payout”</strong>
          </Typography>
        </Box>
      </InfoBlock>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Member</StyledTableCell>
              <StyledTableCell>IND DED</StyledTableCell>
              <StyledTableCell>IND OOP</StyledTableCell>
              <StyledTableCell colSpan={4} />
            </TableRow>
          </TableHead>
          <TableBody>
            {policyTracking?.policyTrackingPayout ? (
              policyTracking?.policyTrackingPayout?.indTrackings.map(
                (item, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{item.member}</StyledTableCell>

                      <StyledTableCell>{`$${item.indDedTracking.toFixed(
                        2
                      )} / $${item.indDedInn.toFixed(2)}`}</StyledTableCell>
                      <StyledTableCell>{`$${item.indOopTracking.toFixed(
                        2
                      )} / $${item.indMaxInn.toFixed(2)}`}</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                  );
                }
              )
            ) : (
              <TableRow>
                <StyledTableCell>No records</StyledTableCell>
              </TableRow>
            )}

            <TableRow sx={{ backgroundColor: "#f0f1f8" }}>
              <StyledTableCell>FAM TOTALS</StyledTableCell>
              <StyledTableCell>{`$${
                policyTracking?.policyTrackingPayout?.indDedTracking.toFixed(
                  2
                ) || 0
              } / $${
                policyTracking?.policyTrackingPayout?.famDedInn.toFixed(2) || 0
              }`}</StyledTableCell>
              <StyledTableCell>{`$${
                policyTracking?.policyTrackingPayout?.indOopTracking.toFixed(
                  2
                ) || 0
              } / $${
                policyTracking?.policyTrackingPayout?.famOopMaxInn.toFixed(2) ||
                0
              }`}</StyledTableCell>
              <StyledTableCell />
              <StyledTableCell>GA PAYOUT</StyledTableCell>
              <StyledTableCell>{`$${
                policyTracking?.policyTrackingPayout?.gaTotalPayout.toFixed(
                  2
                ) || 0
              } / $${
                policyTracking?.policyTrackingPayout?.gaMax.toFixed(2) || 0
              }`}</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
