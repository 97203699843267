import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SignOut24Regular, PersonCircle24Regular } from "@fluentui/react-icons";
import { loginRequest } from "../../authConfig";
import { useGetProviderNameQuery } from "../../services/InsuranceService";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import {
  LogoContainer,
  NavBar,
  NavigationBarButton,
  NavigationBarContainer,
  NavigationBarUser,
} from "./NavigationBar.styled";
import logoImg from "../../shared/img/fridaylogo.png";

const StyledLink = styled(Link)(({ theme }) => ({
  font: '400 20px "Oswald"',
  color: "#fff",
  textDecoration: "none",
  alignItems: "center",
  display: "flex",
  height: "30px",
}));
const AuthenticatedNavBar = () => {
  const { skip } = useFetchWithMsal();
  const { isProvider } = useSelector((state) => state.user);

  const { data } = useGetProviderNameQuery("", {
    skip: !(isProvider && !skip),
  });
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  return (
    <>
      <NavigationBarContainer>
        <StyledLink to="/">
          {"Nevada Life & Health Insurance Guaranty Association"}
        </StyledLink>
        <Box display="flex">
          <NavigationBarUser>
            <PersonCircle24Regular />
            <Typography margin={"0 8px"}>
              {`${activeAccount?.idTokenClaims.given_name || ""}
            ${data ? " / " + data?.providerName : ""}`}
            </Typography>
          </NavigationBarUser>

          <NavigationBarButton
            onClick={handleLogoutRedirect}
            variant="outlined"
          >
            <SignOut24Regular />
            Sign out
          </NavigationBarButton>
        </Box>
      </NavigationBarContainer>
      <LogoContainer>
        <div>
          <img src={logoImg} alt="" />
        </div>
      </LogoContainer>
    </>
  );
};

export const NavigationBar = () => {
  const { instance } = useMsal();
  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };
  return (
    <>
      <NavBar>
        <AuthenticatedTemplate>
          <AuthenticatedNavBar />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <NavigationBarContainer>
            <StyledLink to="/">
              {"Nevada Life & Health Insurance Guaranty Association"}
            </StyledLink>
            <NavigationBarButton
              onClick={handleLoginRedirect}
              variant="outlined"
            >
              <SignOut24Regular />
              Sign in
            </NavigationBarButton>
          </NavigationBarContainer>
        </UnauthenticatedTemplate>
      </NavBar>
    </>
  );
};
