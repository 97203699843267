import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import moment from "moment";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
import { useGetComplaintAppealsQuery } from "../../../../services/InsuranceService";
import { setError } from "../../../../store/slices/ErrorSlice";
import StyledGrid from "../../../../shared/components/StyledGrid";
import { complaintColumn } from "../../../../constants/ComplaintColumn";

function getStatusClass(currentStatus) {
  switch (currentStatus) {
    case "Closed":
      return "status closed-status";
    case "Open":
      return "status open-status";
    case "Research":
      return "status research-status";
    default:
      return "";
  }
}

export const ComplaintTab = () => {
  const { skip } = useFetchWithMsal();
  const { data, error, isError } = useGetComplaintAppealsQuery("", {
    skip,
  });
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      dispatch(setError(error?.message));
    }
  }, [error, isError]);

  // const onRowClick = (row) => () => {
  //   dispatch(setIsOpen(true));
  //   dispatch(setComplaintData(row));
  // };

  const columns = complaintColumn.map((item) => {
    switch (item.type) {
      case "date":
        return {
          field: item.field,
          headerName: item.headerName,
          minWidth: 120,
          flex: 1,
          sortable: false,
          valueGetter: (params) =>
            params.row[item.field]
              ? moment(params.row[item.field]).utc().format("MM/DD/YYYY")
              : "",
        };
      case "status":
        return {
          field: item.field,
          headerName: item.headerName,
          minWidth: 120,
          flex: 1,
          sortable: false,
          renderCell: (params) => (
            <div className={getStatusClass(params.row.currentStatus)}>
              {params.row.currentStatus}
            </div>
          ),
        };
      default:
        return {
          field: item.field,
          headerName: item.headerName,
          flex: 1,
          minWidth: 120,
          sortable: false,
        };
    }
  });

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <StyledGrid data={rows} columns={columns} width={"100%"} top={190} />
    </Box>
  );
};
