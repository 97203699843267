import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { SignOut24Regular } from "@fluentui/react-icons";
import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import InsuranceCollection from "../../components/insuranceCollection/InsuranceCollection";
import { TabPage } from "../TabPage";
import logoImg from "../../shared/img/fridaylogo.png";
import {
  ImgContainer,
  LandingContainer,
  ListContainer,
  ListIcon,
  ListItem,
  StyledButton,
  StyledLink,
} from "./HomePage.styled";
import { loginRequest } from "../../authConfig";

export const HomePage = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const { isProvider } = useSelector((state) => state.user);
  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };
  // const renderHomePageContent = () => {
  //   if (isProvider) {
  //     return <InsuranceCollection />;
  //   }
  //   return <TabPage />;
  // };
  return (
    <>
      <AuthenticatedTemplate>
        {/* {activeAccount ? renderHomePageContent() : null} */}
        <InsuranceCollection />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LandingContainer>
          <ImgContainer>
            <img src={logoImg} alt="" />
          </ImgContainer>

          <Typography variant="h1">
            Friday Health Plans of Nevada, In Liquidation
          </Typography>
          <Typography variant="body2">
            On September 1, 2023, Friday Health Plans of Nevada was placed into
            liquidation in Nevada, triggering coverage from the Nevada Life and
            Health Insurance Guaranty Association. The Guaranty Association,
            will process all claims for services provided before midnight on
            August 31st pursuant to the terms of the policy and up to a
            statutory limit of $500,000. Services occurring after August 31st
            will not be considered for payment. Please do not resubmit claims as
            this will delay processing. For unsubmitted claims, please continue
            to use the current processes. For additional information, please
            refer to the resources below:
          </Typography>
          <ListContainer>
            <ListItem>
              <ListIcon />
              <Typography variant="body2">
                <StyledLink href="https://www.nvlifega.org/AdditionalInfo/Open/1032/FHPNVFAQ">
                  FAQs
                </StyledLink>
              </Typography>
            </ListItem>
            <ListItem>
              <ListIcon />
              <Typography variant="body2"> Phone: (909) 797-0546 </Typography>
            </ListItem>
            <ListItem>
              <ListIcon />
              <Typography variant="body2">{"Email: "}</Typography>
              <StyledLink href="mailto:info@nvlifega.org">
                info@nvlifega.org
              </StyledLink>
            </ListItem>
          </ListContainer>
          <StyledButton
            variant="contained"
            startIcon={<SignOut24Regular />}
            onClick={handleLoginRedirect}
          >
            Sign in to view claim status
          </StyledButton>
          <Typography variant="body2">For those new to the platform, simply click the "Sign Up Now" link on the login page to begin your registration.</Typography>
        </LandingContainer>
      </UnauthenticatedTemplate>
    </>
  );
};
