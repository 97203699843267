import * as React from "react";
import { DataGrid, GridFilterPanel } from "@mui/x-data-grid";
import { Stack, styled } from "@mui/material";

const StyledDataGrid = styled(DataGrid)((props) => ({
  width: props.width || "fit-content",
  minWidth: "50px",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  fontFamily: "Lato",
  fontSize: "12px",
  margin: '0 auto',
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1px solid #f0f0f0",
  },
  "& .MuiDataGrid-cell": {
    color: "rgba(0,0,0,.85)",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  "& .MuiDataGrid-main": {
    overflow: "unset",
  },
  "& .MuiDataGrid-columnHeaders": {
    position: "sticky",
    top: props.top || 36,
    zIndex: 100,
    backgroundColor: "#63cae1",
    color: "#fff",
  },
  "& .MuiDataGrid-virtualScroller": {
    marginTop: "0!important",
    minHeight: "100px",
  },
  "& .link": {
    color: "#5C068C",
    textDecoration: "none",
  },
  "& .status": {
    padding: "5px 12px",
    borderRadius: "20px",
  },
  "& .status": {
    padding: "5px 12px",
    borderRadius: "20px",
    color: "#fff",
  },
  "& .status.closed-status": {
    backgroundColor: "#00168999",
  },
  "& .status.open-status": {
    color: "#5C0F8B",
    backgroundColor: "#63CAE1",
  },
  "& .status.research-status": {
    backgroundColor: "#8A1A9B",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    lineHeight: '20px',
    textWrap: 'wrap',
  },
}));


const StyledStack = styled(Stack)((props) => ({
  letterSpacing: "normal",
  fontFamily: "Lato",
  fontSize: "20px",
  color: "#5c088c",
}))



const StyledGrid = (props) => {
  const {
    data,
    columns,
    width,
    top,
    withFilter,
    withMenu,
    onFilterModelChange,
  } = props;

  return (
    <StyledDataGrid
      width={width}
      top={top}
      rows={data}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter={withFilter ? false : true}
      disableColumnMenu={withMenu ? false : true}
      {...(onFilterModelChange
        ? { onFilterModelChange: onFilterModelChange }
        : {})}
      disableColumnSelector
      hideFooter
      filterMode="server"
      components={{
        FilterPanel: (props) => (
          <div style={{ minWidth: "650px" }}>
            <GridFilterPanel {...props} />
          </div>
        ),
        NoRowsOverlay: () => (
          <StyledStack height="100%" alignItems="center" justifyContent="center">
            No data to show
          </StyledStack>
        ),
        NoResultsOverlay: () => (
          <StyledStack height="100%" alignItems="center" justifyContent="center">
            No results
          </StyledStack>
        )
      }}
    />
  );
};

export default StyledGrid;
