import { Button, Link } from "@mui/material";
import { Box, styled } from "@mui/system";
import { ArrowCircleRight16Regular } from "@fluentui/react-icons";

export const LandingContainer = styled(Box)({
  maxWidth: "1200px",
  margin: "auto",
  padding: "10px 0",
});
export const ImgContainer = styled(Box)({
  marginBottom: "20px",
  "& img": {
    height: "48px",
  },
});
export const ListContainer = styled(Box)({
  marginTop: "24px",
});
export const ListItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
});
export const StyledLink = styled(Link)(({ theme }) => ({
  font: theme.typography.link.font,
  color: theme.palette.link.main,
  textDecoration: "none",
  alignItems: "center",
  display: "flex",
}));
export const ListIcon = styled(ArrowCircleRight16Regular)(({ theme }) => ({
  color: theme.palette.link.main,
  height: "14px",
  width: "14px",
  marginRight: "10px",
}));
export const StyledButton = styled(Button)(({ theme }) => ({
  font: theme.typography.button.font,
  textTransform: "none",
  marginTop: "41px",
  marginBottom:'20px'
}));
