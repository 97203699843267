import React from "react";
import {
  ButtonWithoutStyle,
  ToolsButtonGroup,
  ToolsContainer,
} from "./TabInfoTools.styled";
import { Typography } from "@mui/material";

export const TabInfoTools = ({ onCollapseClick, onExpandAll, userName }) => {
  return (
    <ToolsContainer>
      <ToolsButtonGroup>
        <Typography variant="h1">{userName}</Typography>
      </ToolsButtonGroup>
      <ToolsButtonGroup>
        <ButtonWithoutStyle onClick={onExpandAll} type="button">
          <Typography variant="subtitle1">Expand all</Typography>
        </ButtonWithoutStyle>
        <Typography variant="subtitle1"> &#8226; </Typography>
        <ButtonWithoutStyle onClick={onCollapseClick} type="button">
          <Typography variant="subtitle1"> Collapse all</Typography>
        </ButtonWithoutStyle>
      </ToolsButtonGroup>
    </ToolsContainer>
  );
};
