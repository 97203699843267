import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
import { useGetClaimsQuery } from "../../../../services/InsuranceService";
import { useDispatch } from "react-redux";
import { setError } from "../../../../store/slices/ErrorSlice";
import StyledGrid from "../../../../shared/components/StyledGrid";
import moment from "moment";
import { insuranceCollectionColumn } from "../../../../constants/InsuranceCollectionColumn";

const filteredColumns = insuranceCollectionColumn.filter(
  (item) => !["id", "insolvency", "payToProvider"].includes(item.field)
);
const columns = filteredColumns.map((item) => {
  switch (item.type) {
    case "yesNo":
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
        valueGetter: (params) => (params.row.payToProvider ? "Yes" : "No"),
      };
    case "period":
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
        valueGetter: (params) =>
          `${moment(params.row.serviceStartDate)
            .utc()
            .format("MM/DD/YYYY")} - ${moment(params.row.serviceEndtDate)
            .utc()
            .format("MM/DD/YYYY")}`,
      };
    case "date":
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
        valueGetter: (params) =>
          moment(params.row[item.field]).utc().format("MM/DD/YYYY"),
      };
    case "currency":
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
        valueGetter: (params) => `$${params.row[item.field].toFixed(2)}`,
      };
      case "eobNumber":
      return {
        field: item.field,
        headerName: 'Claim Number',
        width: item.width,
        sortable: false,
      };
    default:
      return {
        field: item.field,
        headerName: item.headerName,
        width: item.width,
        sortable: false,
      };
  }
});

export const ClaimsTab = ({ policyNumber }) => {
  const { skip } = useFetchWithMsal();
  const { data, error, isError } = useGetClaimsQuery(policyNumber, {
    skip,
  });
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      dispatch(setError(error?.message));
    }
  }, [error, isError]);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <StyledGrid data={rows} columns={columns} width={"100%"} top={143} />
    </Box>
  );
};
