import { Box, styled } from "@mui/system";

export const AddressGroup = styled(Box)({
  display: "grid",
  gridAutoFlow: "column",
  gap: "7px",
  alignItems: "center",
});
export const InputContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: "95px 1fr",
  alignItems: "center",
  marginBottom: "12px",
});
export const InfoBlock = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom:'25px'
});
